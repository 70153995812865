<template>
  <div class="dcpic">
    <Header class="app-title" theme="dark" @click="goDcpicList(3)">
      <template>华夏AI</template>
    </Header>
    <div class="container">
      <div class="dcpic-text">
        <img src="../../assets/images/dcpic/success.png" />
      </div>
      <div class="dcpic1-border">
        <div>{{ prompt }}</div>
        <div class="dcpic1-border-after"></div>
        <div class="dcpic1-border-before"></div>
        <div class="num-tag">
          <img :src="url" />
        </div>
      </div>
      <div class="dcpic-botton-1" @click="showDetail">
        <div>查看藏品</div>
      </div>
    </div>
  </div>
</template>
<script>
import { addBridgeForAndroidWebView } from 'sdbridge-android'

export default {
  data() {
    return {
      bridge: '',
      prompt: '',
      url: '',
      token: '',
      pid: '',
      isAndroid:
        navigator.userAgent.indexOf('Android') > -1 ||
        navigator.userAgent.indexOf('Adr') > -1,
    }
  },
  methods: {
    showDetail() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: 16 }))
        } else {
          this.bridge.callHandler(
            'jumptoapp',
            { key: 16 },
            function (response) {
              console.log(JSON.stringify(response))
            }
          )
        }
      } else {
        this.$router.push({
          path: '/user',
        })
      }
    },
    goDcpicList(tag) {
      this.$router.push({
        path: '/dcpiclist',
        query: { tag: tag, token: this.token, id: this.pid },
      })
    },
  },
  mounted() {
    this.url = this.$route.query.url
    this.prompt = this.$route.query.prompt
    this.token = this.$route.query.token
    this.pid = this.$route.query.pid

    let bridge = window.WebViewJavascriptBridge
    if (!bridge) {
      console.log('TypeScript正在尝试挂载')
      addBridgeForAndroidWebView()
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge
      if (bridge) {
        console.log('window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!')
        this.bridge = bridge
      }
    } else {
      this.bridge = bridge
      console.log('window.WebViewJavascriptBridge 挂载成功！！')
    }
  },
}
</script>
<style lang="less" scoped>
.dcpic {
  width: 100%;
  color: #ffffff;
  background: #02192b;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: auto;
}
.app-title {
  background: #23272a !important;
}
.container {
  padding: 0 16px;
  box-sizing: border-box;
  text-align: center;
}
.dcpic-text > img {
  width: 170px;
  margin-top: 25px;
}
.dcpic1-border-after {
  content: '';
  position: absolute;
  top: -15px;
  right: -15px;
  width: 28px;
  height: 27px;
  border-image: linear-gradient(180deg, #c94be7, #b470e8) 1;
  border-left: 1px solid;
  transform: rotate(-46deg);
  background: #02192b;
}
.dcpic1-border-before {
  content: '';
  position: absolute;
  bottom: -15px;
  left: -15px;
  width: 28px;
  height: 27px;
  border-image: linear-gradient(180deg, #b631ee, #d72ae8) 1;
  border-right: 1px solid;
  transform: rotate(-46deg);
  background: #02192b;
}
.dcpic1-border {
  position: relative;
  margin-top: 15px;

  border: 1px solid;
  border-image: linear-gradient(-60deg, #27e8e6, #d829e7, #733dfe) 10 10;
  background: radial-gradient(circle, #171d48 0%, rgba(2, 25, 43, 0) 100%);
  width: 340px;

  min-height: 370px;
  padding: 0 12px;
  box-sizing: border-box;
  > div:first-child {
    position: relative;
    margin: 11px 0;
    min-height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #f6edff;
    line-height: 20px;
    // text-align: left;
    word-wrap: break-word;
  }
  > div:last-child > img {
    width: 316px;
    height: 316px;
  }
}
.dcpic-botton-1 {
  width: 100%;
  height: 44px;
  margin-top: 27px;
  margin-bottom: 14px;
  background: linear-gradient(44deg, #440edc 0%, #9c2df5 100%);
  border-radius: 5px;
  > div {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 44px;
  }
}
</style>
